import { defineStore } from "pinia";
import axios from "axios";
import type Product from "~/types/Product";
import { useUserStore } from "~/store/user";

export const useWishlistStore = defineStore("wishlistStore", {
    persist: false,
    state: () => ({
        items: [] as Product[],
    }),
    getters: {},
    actions: {
        itemExist(productId: number) {
            const itemIds = this.items.map((product: Product) => product.id);
            if (itemIds.includes(productId)) {
                return true;
            }
            return false;
        },
        async fetchShareItems(userId: string) {
            try {
                if (!userId || isNaN(Number(userId))) {
                    return [];
                }

                const config = useRuntimeConfig();
                const url = `${config.public.STATAMIC_API}/store/share-wishlist/${userId}`;
                const response = await axios.get(url);

                return response.data;
            } catch (error) {
                console.log(error);
            }
            return [];
        },
        async fetchItems(refresh = false) {
            try {
                // Wishlist require logged-in user
                if (!useUserStore().isLoggedIn) {
                    return [];
                }

                if (refresh || this.items.length <= 0 || !this.items[0].id) {
                    const config = useRuntimeConfig();
                    const url = `${config.public.STATAMIC_API}/store/wishlist`;
                    const response = await axios.get(url);

                    this.items = response.data;
                }

                return this.items;
            } catch (error) {
                console.log(error);
            }
            return [];
        },
        async insertItem(productId: number) {
            try {
                const config = useRuntimeConfig();
                const url = `${config.public.STATAMIC_API}/store/wishlist/${productId}`;
                const response = await axios.post(url, {});

                await this.fetchItems(true);

                return this.items;
            } catch (error) {
                console.log(error);
            }
            return [];
        },
        async deleteItem(productId: number) {
            try {
                if (!this.itemExist(productId)) {
                    return;
                }

                const config = useRuntimeConfig();
                const url = `${config.public.STATAMIC_API}/store/wishlist/${productId}`;
                const response = await axios.delete(url);

                await this.fetchItems(true);

                return this.items;
            } catch (error) {
                console.log(error);
            }
            return [];
        },
    },
});
